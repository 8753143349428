import Vue from 'vue'
import { get } from 'lodash'
import App from './App.vue'
import router from './routes'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from './plugins/firebase'
import { validatePermissions } from '@/utils/auth'
import './plugins/vue-datetime'
import './plugins/vue-cookie'
import './assets/scss/main.scss'
import './registerServiceWorker'
import './services/api'
import './services/env'
import './models'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'

Vue.use(VueTelInputVuetify, {
  vuetify,
})

Vue.config.productionTip = false
store.dispatch('auth/init')

router.beforeEach(async (to, from, next) => {
  // Check if the route requires permission
  const requiresPermissions = get(to, 'meta.requiresPermissions')
  const userPermissions = get(store, 'state.auth.permissions', [])

  if (!validatePermissions(requiresPermissions, userPermissions)) {
    if (from.path !== to.path) {
      next(from.path)
    } else {
      next('/')
    }
    store.dispatch('snackbar/show', {
      message: "You don't have permission.",
      list: '',
      color: 'red',
    })
    return
  }

  // Proceed to the route
  next()
})

new Vue({
  router,
  store,
  vuetify,
  firebase,
  render: (h) => h(App),
}).$mount('#app')
