import Api from '@/services/api'
import { get } from 'lodash'
import WorkOrder from '@/models/WorkOrder'

export default {
  namespaced: true,
  state: {
    list: [],
    workOrderDetails: null,
    totalWorkOrders: 0,
    tempList: [],
    error: null,
  },

  mutations: {
    setWorkOrderList(state, workOrders) {
      state.list = workOrders
    },

    clearWorkOrderList(state) {
      state.list = []
    },

    setError(state, error) {
      state.error = error
    },

    clearError(state) {
      state.error = null
    },

    setWorkOrderDetails(state, workOrder) {
      if (workOrder instanceof WorkOrder) {
        state.workOrderDetails = workOrder
      } else {
        state.workOrderDetails = new WorkOrder(workOrder)
      }
    },

    setTotalWorkOrders(state, total) {
      state.totalWorkOrders = total
    },

    clearTempWorkOrders(state) {
      state.tempList = []
    },

    deleteTempWorkOrder(state, id) {
      if (state.tempList.length > 0) {
        state.tempList = state.tempList.filter((item) => item.id !== id)
      }
    },
  },

  actions: {
    async getWorkOrders({ commit, rootGetters }, params) {
      const query = WorkOrder.page(params.page || 1)
        .limit(params.itemsPerPage || 5)
        .orderBy(params.sort)
      query.params({ paginate: true })

      if (params.sort) {
        query.orderBy(params.sort)
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      if (params.supplierId) {
        query.where('supplier_id', params.supplierId)
      }

      if (params.maintenanceId) {
        query.where('maintenance_id', params.maintenanceId)
      }

      query.include(['supplier,maintenance,file'])

      const res = await query.get()

      if (get(res, 'meta.total', 0) > 0) {
        commit('setTotalWorkOrders', get(res, 'meta.total', 0))
      }
      commit('setWorkOrderList', get(res, 'data', []))
    },

    async clearTempWorkOrders({ commit }, formData) {
      await Api.post(`work_orders/bulk-delete`)
      commit('clearTempWorkOrders')
    },

    deleteTempWorkOrder({ commit }, id) {
      commit('deleteTempWorkOrder', id)
    },
  },
}
