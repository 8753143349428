import Asset from '@/models/Asset'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    assetDetails: null,
    totalAssets: 0,
    queryParams: null,
    scrollPosition: 0,
  },

  mutations: {
    setAssetList(state, assets) {
      state.list = assets
    },

    clearAssetList(state) {
      state.list = []
      state.totalAssets = 0
      state.assetStatusNews = []
      state.assetStatusInprogress = []
      state.totalAssetStatusNews = 0
      state.totalAssetStatusInprogress = 0
    },

    setAssetListMeta(state, meta) {
      state.listMeta = meta
    },

    setAssetDetails(state, asset) {
      if (state.assetDetails) {
        if (!asset.building) asset.building = state.assetDetails.building
      }

      if (asset instanceof Asset) {
        state.assetDetails = asset
      } else {
        state.assetDetails = new Asset(asset)
      }
    },

    clearAssetDetails(state) {
      state.assetDetails = null
    },

    setTotalAssets(state, total) {
      state.totalAssets = total
    },

    setAssetImage(state, payload) {
      if (state.assetDetails) {
        const existing = Array.isArray(state.assetDetails.media)
          ? state.assetDetails.media
          : []

        state.assetDetails.media = [...existing, ...payload]
      }
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getAssets({ commit, rootGetters }, params) {
      const query = Asset.page(params.page || 1).orderBy(params.sort)

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      if (params.sort) {
        const sort = params.sort.replace('buildingName', 'building')
        query.orderBy(sort)
      }

      // Add building filter if not nil
      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        query.where('building_id', filteredBuilding)
      }

      query.include(['building'])

      const res = await query.params({ limit: params.itemsPerPage || 30 }).get()

      commit('setAssetList', res.data)
      commit('setAssetListMeta', res.meta)
      if (get(res, 'meta.total', 0) > 0) {
        commit('setTotalAssets', get(res, 'meta.total', 0))
      }
    },

    async getAssetDetails({ commit }, id) {
      const { data } = await Api.get(`assets/${id}?include=media`)
      commit('setAssetDetails', data.data)
    },

    async updateAsset({ commit }, formData) {
      const { data } = await Api.put(`assets/${formData.id}`, formData)
      commit('setAssetDetails', data.data)
    },

    async createAsset({ commit }, formData) {
      const { data } = await Api.post(`assets`, formData)
      commit('setAssetDetails', data.data)
    },

    async uploadAssetImages({ commit }, formData) {
      const { data } = await Api.post(
        `assets/${formData.get('id')}/images`,
        formData
      )

      commit('setAssetImage', data.data)
    },

    async deleteAssetImage({ commit }, formData) {
      await Api.delete(`assets/${formData.id}/images/${formData.image}`)
    },

    async getForSelects({ commit, rootGetters }, params) {
      const query = Asset.params({
        paginate: false,
      })

      query.where('is_active', true)

      if (params.filter.buildingId) {
        query.where('building_id', params.filter.buildingId)
      }

      const res = await query.get()

      return res.data
    },
  },
}
