import EntryPermission from '@/models/EntryPermission'
import Api from '@/services/api'
import { get, each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    entryPermissionDetails: null,
    totalEntryPermissions: 0,
    queryParams: null,
    scrollPosition: 0,
    initList: [],
  },

  mutations: {
    setEntryPermissionList(state, entryPermissions) {
      each(entryPermissions, (entryPermission) => {
        const exist = find(state.list, { id: entryPermission.id })
        if (!exist) {
          state.list.push(new EntryPermission(entryPermission))
        }
      })
    },

    setInitPermissions(state, entryPermissions) {
      each(entryPermissions, (entryPermission) => {
        const exist = find(state.initList, { id: entryPermission.id })
        if (!exist) {
          state.initList.push(new EntryPermission(entryPermission))
        }
      })
    },

    clearEntryPermissionList(state) {
      state.list = []
      state.initList = []
      state.totalEntryPermissions = 0
    },

    setEntryPermissionListMeta(state, meta) {
      state.listMeta = meta
    },

    setEntryPermissionDetails(state, entryPermission) {
      if (state.entryPermissionDetails) {
        if (!entryPermission.thumbnail_banner)
          entryPermission.thumbnail_banner =
            state.entryPermissionDetails.thumbnail_banner
      }

      if (entryPermission instanceof EntryPermission) {
        state.entryPermissionDetails = entryPermission
      } else {
        state.entryPermissionDetails = new EntryPermission(entryPermission)
      }
    },

    clearEntryPermissionDetails(state) {
      state.entryPermissionDetails = null
    },

    setEntryPermissionTotals(state, total) {
      state.totalEntryPermissions = total
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getEntryPermissions({ commit, rootGetters }, params) {
      if (params) {
        const query = EntryPermission.page(params.page || 1)
          .limit(params.itemsPerPage || 10)
          .orderBy(params.sort)
          .params({ paginate: true })
          .where('is_active', params.status === 0)

        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        // Add building filter if not nil
        let filteredBuilding = rootGetters['filter/buildingId']
        if (filteredBuilding) {
          query.where('building_id', filteredBuilding)
        }

        query.include(['buildings'])

        const res = await query.get()
        commit('setEntryPermissionList', res.data)
        commit('setEntryPermissionListMeta', res.meta)
        if (get(res, 'meta.total', 0) > 0) {
          commit('setEntryPermissionTotals', get(res, 'meta.total', 0))
        }
        commit('setInitPermissions', res.data)
      } else {
        const res = await EntryPermission.params({ paginate: false }).get()
        return res.data
      }
    },

    async getForSelects({ commit, rootGetters }, params) {
      const query = EntryPermission.params({ paginate: false })
      query.where('is_active', true)

      if (params.filter.buildingId) {
        query.where('building_id', params.filter.buildingId)
      }

      const res = await query.get()

      return res.data
    },

    async getEntryPermissionDetails({ commit }, id) {
      const { data } = await Api.get(
        `entry_permissions/${id}/?include=buildings`
      )
      commit('setEntryPermissionDetails', data.data)
    },

    async createEntryPermission({ commit }, formData) {
      const { data } = await Api.post(`entry_permissions`, formData)
      commit('setEntryPermissionDetails', data.data)
    },

    async updateEntryPermission({ commit }, formData) {
      const { data } = await Api.put(
        `entry_permissions/${formData.id}/?include=buildings`,
        formData
      )
      commit('setEntryPermissionDetails', data.data)
    },

    async archiveEntryPermission({ commit }, { id, formData }) {
      const { data } = await Api.post(
        `entry_permissions/${id}/archive/?_method=PUT`,
        formData
      )
      return data.data
    },

    async updatePositions({ commit }, formData) {
      const { data } = await Api.post(
        `entry_permissions/updatePositions`,
        formData
      )
      return data
    },
  },
}
