const ROLE = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  ADMIN_AI: 'ADMIN_AI',
  USER: 'USER',
  STANDARD: 'STANDARD',
  READ_ONLY: 'READ_ONLY',
}

export default ROLE
