import GoogleLibPhoneNumber from 'google-libphonenumber'

const PHONE_UTIL = GoogleLibPhoneNumber.PhoneNumberUtil.getInstance()
const PNF = GoogleLibPhoneNumber.PhoneNumberFormat
const PNT = GoogleLibPhoneNumber.PhoneNumberType
const AU_REGION = 'AU'
const PH_REGION = 'PH'
const NZ_REGION = 'NZ'

function callableFormat(phone) {
  if (!phone) return

  const regExp = /^0[0-9].*$/
  return regExp.test(phone) ? phone : `+${phone}`
}

function isValidMobileNumber(value) {
  // Remove spaces from the input
  const cleanedValue = value.replace(/\s/g, '')

  if (!cleanedValue || !/^\d+$/.test(cleanedValue)) return false

  const REGIONS = [AU_REGION, PH_REGION, NZ_REGION]

  return REGIONS.some((region) => {
    const number = PHONE_UTIL.parseAndKeepRawInput(cleanedValue, region)
    return (
      PHONE_UTIL.isValidNumberForRegion(number, region) &&
      isInNationalFormat(number) &&
      isNumberType(number, PNT.MOBILE)
    )
  })
}

function isValidTelephoneNumber(value) {
  // Remove spaces from the input
  const cleanedValue = value.replace(/\s/g, '')
  if (!cleanedValue || !/^\d+$/.test(cleanedValue)) return false

  const REGIONS = [AU_REGION, PH_REGION, NZ_REGION]
  const VALID_TYPES = [
    PNT.FIXED_LINE,
    PNT.TOLL_FREE,
    PNT.SHARED_COST,
    PNT.FIXED_LINE_OR_MOBILE,
  ]

  return REGIONS.some((region) => {
    const number = PHONE_UTIL.parseAndKeepRawInput(cleanedValue, region)
    return (
      PHONE_UTIL.isValidNumberForRegion(number, region) &&
      VALID_TYPES.some((type) => isNumberType(number, type))
    )
  })
}

function isInNationalFormat(number) {
  const nationalFormat = PHONE_UTIL.format(number, PNF.NATIONAL).replace(
    /[^A-Z0-9]/gi,
    ''
  )

  return nationalFormat === number.getRawInput()
}

function isNumberType(number, type) {
  return PHONE_UTIL.getNumberType(number) === type
}

function getRegion(number) {
  console.log(PHONE_UTIL.getRegionCodeForNumber(number))

  return PHONE_UTIL.getRegionCodeForNumber(number) || 'AU'
}

export {
  callableFormat,
  isValidMobileNumber,
  isValidTelephoneNumber,
  getRegion,
}
